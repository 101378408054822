import styled from 'styled-components';

import { grid } from '../../config';
import { media } from '../../utils/responsive';

const GridContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 ${grid.gutters.small}px;

  ${media.medium`
    padding: 0 ${grid.gutters.medium}px;
  `};
`;

export default GridContainer;
