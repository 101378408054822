import React from 'react';
import styled from 'styled-components';

import { colors } from '../config';
import { sansSerif } from '../utils/typography';
import GridContainer from './grid/grid-container';

export const footerHeight = 60;

const FooterStyled = styled.footer`
  background: ${colors.backgroundBlack};
  color: rgba(255, 255, 255, 0.75);
  font-family: ${sansSerif};
  font-size: 14px;
  margin-top: -${footerHeight}px;
`;

const FooterContainer = styled(GridContainer)`
  align-items: center;
  height: ${footerHeight}px;
`;

const Footer = () => {
  const currentDate = new Date();

  return (
    <FooterStyled>
      <FooterContainer>
        &copy; {currentDate.getFullYear()} Mertin Dervish. All rights reserved.
      </FooterContainer>
    </FooterStyled>
  );
};

export default Footer;
