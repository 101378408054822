const headerFontFamily = [
  '-apple-system',
  'blinkmacsystemfont',
  'segoe ui',
  'roboto',
  'oxygen',
  'ubuntu',
  'cantarell',
  'open sans',
  'helvetica neue',
  'sans-serif',
];

module.exports.headerFontFamily = headerFontFamily;
module.exports.bodyFontFamily = headerFontFamily;
module.exports.baseFontSize = '18px';
module.exports.sansSerifFamily = headerFontFamily;
module.exports.serifFamily = ['Georgia', 'serif'];
