import React from 'react';

import Footer from './footer';
import Header from './header/header';
import PageMain from './page/page-main';

export default function Layout({ children, location: { pathname } }) {
  const showHeader = pathname !== '/offline-plugin-app-shell-fallback/';
  const isDark = pathname === '/';

  return (
    <>
      {showHeader && <Header isDark={isDark} />}

      <PageMain>{children}</PageMain>

      <Footer />
    </>
  );
}
