import React from 'react';
import { Link } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';

import { colors } from '../../config';
import { media } from '../../utils/responsive';
import { sansSerif } from '../../utils/typography';
import GridContainer from '../grid/grid-container';
import Logo from '../logo';
import useHeader from './header.hook';

const slideOffScreen = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeaderContainer = styled.header`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  border-bottom: 1px solid transparent;
  transform: translateY(0);
  transition: all ease-in-out 0.5s;
  transition-property: opacity, transform;

  ${({ isDark = false }) =>
    isDark === null &&
    css`
      visbility: hidden;
      opacity: 0;
      transform: translateY(-10px);
    `}

  ${({ isDark = false }) =>
    isDark === false &&
    css`
      animation: ${slideOffScreen} 0.2s ease-in-out 0;
      border-bottom-color: #eaeaea;
      background: #fff;
    `}

  ${media.large`
    ${({ isDark = false }) => isDark === false && 'position: fixed;'}
  `}
`;

const HeaderGridContainer = styled(GridContainer)`
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  ${media.large`
    flex-direction: row;
  `}
`;

const LogoLink = styled(Link)`
  display: block;
  height: 32px;
  margin: 16px 0 0;
  box-shadow: none;

  svg {
    height: 100%;
  }

  ${media.large`
    margin-right: 16px;
    margin-bottom: 16px;
  `}
`;

const Nav = styled.nav`
  width: 100%;
  text-align: center;
  overflow-x: auto;

  ${media.large`
    width: auto;
  `}
`;

const Menu = styled.ul`
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  list-style: none;
  margin: 0;

  ${media.large`
    margin-bottom: 0;
  `}
`;

const MenuItem = styled.li`
  margin: 0 10px;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

// eslint-disable-next-line no-unused-vars
function PureMenuLink({ isDark, ...props }) {
  return <Link activeClassName="menu-link-active" {...props} />;
}

const MenuStyledLink = styled(PureMenuLink)`
  display: block;
  text-transform: uppercase;
  color: ${({ isDark }) => (isDark ? 'rgba(255, 255, 255, .7)' : colors.body)};
  font-family: ${sansSerif};
  font-size: 13px;
  font-weight: 500;
  padding: 16px 0;
  transition: all ease-in-out 0.2s;
  box-shadow: none;

  &:hover,
  &.menu-link-active {
    color: ${({ isDark }) => (isDark ? '#fff' : colors.primary)};
  }

  ${media.large`
    font-size: 16px;
  `}
`;

const MenuLink = ({ to, isDark, children }) => (
  <MenuStyledLink to={to} isDark={isDark}>
    {children}
  </MenuStyledLink>
);

export default function Header() {
  const { isDark } = useHeader();

  return (
    <HeaderContainer isDark={isDark}>
      <HeaderGridContainer>
        <LogoLink to={'/'} title="Home page">
          <Logo isDark={isDark} />
        </LogoLink>

        <Nav>
          <Menu>
            <MenuItem>
              <MenuLink to={'/'} isDark={isDark}>
                Home
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to={'/blog/'} isDark={isDark}>
                Blog
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to={'/contact/'} isDark={isDark}>
                Contact
              </MenuLink>
            </MenuItem>
          </Menu>
        </Nav>
      </HeaderGridContainer>
    </HeaderContainer>
  );
}
