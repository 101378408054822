import Typography from 'typography';
import Wordpress2016 from 'typography-theme-wordpress-2016';

import breakpoints from '../config/breakpoints';
import {
  baseFontSize,
  bodyFontFamily,
  headerFontFamily,
  sansSerifFamily,
  serifFamily,
} from './typography-constants';

Wordpress2016.headerFontFamily = headerFontFamily;
Wordpress2016.bodyFontFamily = bodyFontFamily;
Wordpress2016.baseFontSize = baseFontSize;

Wordpress2016.overrideThemeStyles = () => ({
  html: {
    scrollPaddingTop: '16px',
  },

  [`@media screen and (min-width: ${breakpoints.large}px)`]: {
    html: {
      scrollPaddingTop: '80px', // 16 + sticky header height
    },
  },

  'a.gatsby-resp-image-link': {
    boxShadow: 'none',
  },

  a: {
    color: '#662d8c',
    boxShadow: 'none',
  },

  'h1, h2, h3, h4': {
    fontFamily: sansSerif,
  },

  '.emojione': {
    margin: 0,
    height: Wordpress2016.baseFontSize,
  },

  '[data-whatintent=mouse] *:focus': {
    outline: 'none',
  },

  video: {
    maxWidth: '100%',
  },

  'blockquote p': {
    marginBottom: '0.75rem',
  },
});

delete Wordpress2016.googleFonts;

const typography = new Typography(Wordpress2016);

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles();
}

export default typography;

export const rhythm = typography.rhythm;
export const scale = typography.scale;
export const sansSerif = sansSerifFamily.join(', ');

export { sansSerifFamily, serifFamily };
