const colors = {
  primary: '#E2126D',
  secondary: '#662d8c',
  body: '#242424',
  lighterBody: '#575757',
  anchor: '#ac1153',
  backgroundBlack: '#111111',

  accomplishments: {
    blue: {
      from: '#2e3192',
      to: '#23d2ff',
    },
    purple: {
      from: '#42275a',
      to: '#e251cc',
    },
    orange: {
      from: '#ff4e50',
      to: '#f9d423',
    },
  },

  alert: {
    error: '#e74c3c',
    success: '#2ecc71',
  },

  meteorGradients: {
    pinkOrange: {
      from: '#D4145A',
      to: '#FBB03B',
    },
    lightDarkBlue: {
      from: '#1BFFFF',
      to: '#2E3192',
    },
    pinkPurple: {
      from: '#ED1E79',
      to: '#662D8C',
    },
    pinkYellow: {
      from: '#D74177',
      to: '#FFE98A',
    },
    greenYellow: {
      from: '#009245',
      to: '#FCEE21',
    },
    redYellow: {
      from: '#ED1C24',
      to: '#FCEE21',
    },
    lightDarkPurple: {
      from: '#852D91',
      to: '#312A6C',
    },
    purpleBlue: {
      from: '#4F00BC',
      to: '#29ABE2',
    },
  },
};

colors.button = {
  white: '#fff',
  primary: colors.primary,
  blue: '#0079AD',
};

export default colors;
