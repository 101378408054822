import React, { useMemo, useState } from 'react';

import { HeaderContext } from './header-context';

export default function HeaderProvider({ children }) {
  const [isDark, setIsDark] = useState(null);
  const contextValue = useMemo(() => ({ isDark, setIsDark }), [isDark]);

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  );
}
