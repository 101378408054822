import styled from 'styled-components';

import { footerHeight } from '../footer';

const PageMain = styled.main`
  min-height: 100vh;
  padding-bottom: ${footerHeight}px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export default PageMain;
