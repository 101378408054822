import { keyframes } from 'styled-components';

const animation = {
  transition: 'all ease-in-out 0.2s',
  fadeIn: keyframes`
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  `,
  fadeInHorizontal(translateX = '-10px') {
    return keyframes`
      from {
        opacity: 0;
        transform: translateX(${translateX});
      }

      to {
        opacity: 1;
        transform: translateX(0);
      }
    `;
  },
};

export default animation;
