import { css } from 'styled-components';

import { breakpoints } from '../config';

export const currenViewport = () => {
  const el =
    document.compatMode === 'CSS1Compat'
      ? document.documentElement
      : document.body;

  return {
    width: el.clientWidth,
    height: el.clientHeight,
  };
};

export const max = (breakpoint) => {
  return breakpoints[breakpoint] >= currenViewport().width;
};

export const min = (breakpoint) => {
  return breakpoints[breakpoint] <= currenViewport().width;
};

export const media = Object.keys(breakpoints).reduce((acc, breakpoint) => {
  acc[breakpoint] = (...args) => css`
    @media (min-width: ${breakpoints[breakpoint]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});
