const header = {
  heroHeight: {
    small: 400,
    medium: 800,
  },

  heroPicWidth: {
    small: 300,
    medium: 720,
  },
};

header.heroPicOffset = {
  small: header.heroPicWidth.small * 0.4,
  medium: header.heroPicWidth.medium * 0.4,
};

export default header;
